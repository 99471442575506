@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#dropdown-basic-button {
  width: 100%;
  text-align: left;
  background-color: white;
  color: blue;
}

#dropdown-basic {
  width: 100%;
  text-align: left;
  background-color: white;
  color: blue;
}


body {
    font-family: 'Roboto', sans-serif;
}

#basic-navbar-nav {
    background-color: black;
    color: blue;
}

.h-100 {
    min-height: 100vh;
}

.bg-blue-100 {
    background-color: rgb(187, 222, 251);
}
.bg-indigo-100 {
    background-color: rgb(197, 202, 233);
}
.bg-purple-100 {
    background-color: rgb(225, 190, 231);
}
.bg-pink-100 {
    background-color: rgb(248, 187, 208);
}
.bg-red-100 {
    background-color: rgb(255, 205, 210);
}
.bg-orange-100 {
    background-color: rgb(255, 224, 178);
}
.bg-yellow-100 {
    background-color: rgb(255, 249, 196);
}
.bg-green-100 {
    background-color: rgb(200, 230, 201);
}
.bg-teal-100 {
    background-color: rgb(178, 223, 219);
}
.bg-cyan-100 {
    background-color: rgb(178, 235, 242);
}
.bg-gray-100 {
    background-color: rgb(245, 245, 245);
}
.bg-brown-100 {
    background-color: rgb(215, 204, 200);
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.wrap-text {
    white-space: normal !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    /* max-width: 300px;  */
    /* Add a max-width to see wrapping */
  }
  

/* 
https://getbootstrap.com/docs/5.0/customize/color/
https://materialui.co/colors/indigo/100 
*/

.w75px {
    width: 73px;
}

.w100px {
    width: 110px;
}

.w150px {
    width: 150px;
}

.w200px {
    width: 200px;
}

.w250px {
    width: 250px;
}

.w100pc {
    width: 100%;
}



