.w75px {
    width: 73px;
}

.w100px {
    width: 110px;
}

.w150px {
    width: 150px;
}

.w200px {
    width: 200px;
}

.w250px {
    width: 250px;
}

.w100pc {
    width: 100%;
}


